import React from 'react';
import classNames from 'classnames';
import { type NormalizedPost } from '../../types';
import DotDotDot from '../dotdotdot';
import SimpleRenderer from '../simple-renderer';
import styles from './content-excerpt.scss';

type Props = {
  post: NormalizedPost;
  lineCount: number;
  maxLength: number;
  autoFit?: boolean;
};

const ContentExcerpt: React.FC<Props> = ({
  post,
  lineCount,
  maxLength,
  autoFit,
}) => {
  const className = classNames(styles.container, 'post-excerpt');
  return (
    <div className={className}>
      <DotDotDot
        clamp={autoFit ? 'parent' : lineCount}
        useExactLineHeight={true}
      >
        <SimpleRenderer post={post} maxLength={maxLength} />
      </DotDotDot>
    </div>
  );
};

export default ContentExcerpt;
