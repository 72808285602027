import React from 'react';
import classNames from 'classnames';
import { useSlotPlaceholder } from '@wix/widget-plugins-ooi';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import {
  FEED_LAYOUT_TYPES,
  getLayoutName,
  Layout,
  resolveId,
  SECTION_HOMEPAGE,
} from '@wix/communities-blog-client-common';
import { BELOW_CONTENT_1, BELOW_CONTENT_2 } from '@app/constants/ooi-slots';
import CategoryLabelList from '@app/external/common/components/category-label-list';
import { MoreButton } from '@app/external/common/components/more-button';
import { importPostActions } from '@app/external/common/components/post-actions';
import PostContent from '@app/external/common/components/post-content';
import PostHeaderIcons from '@app/external/common/components/post-header-icons';
import PostMainActions from '@app/external/common/components/post-main-actions';
import PostPageMetadata from '@app/external/common/components/post-page-metadata';
import PostTitle from '@app/external/common/components/post-title';
import RatingsDisplay from '@app/external/common/components/ratings-display';
import { RatingsDisplayLayout } from '@app/external/common/components/ratings-display/ratings-display';
import {
  useActions,
  useSelector,
} from '@app/external/common/components/runtime-context';
import { TagsWithTranslation } from '@app/external/common/components/tags';
import { useFeedMetadataSettings } from '@app/external/common/hooks/use-feed-metadata-settings';
import useFontClassName from '@app/external/common/hooks/use-font-class-name';
import useIsFeedDesignEnabled from '@app/external/common/hooks/use-is-feed-design-enabled';
import usePermissions from '@app/external/common/hooks/use-permissions';
import { usePostBorderWidth } from '@app/external/common/hooks/use-post-border-width';
import { usePostPageSettings } from '@app/external/common/hooks/use-post-page-settings';
import {
  getIsCategoryLabelsEnabled,
  getIsMoreButtonEnabled,
} from '@app/external/common/selectors/app-settings-selectors';
import { shouldApplyPostDesignInFeed as getShouldApplyPostDesignInFeed } from '@app/external/common/selectors/full-post-selectors';
import { getLayoutType } from '@app/external/common/selectors/layout-selectors';
import { getSection } from '@app/external/common/selectors/section-selectors';
import { getPostActions } from '@app/external/common/services/post-actions';
import {
  getIsDesktop,
  isSSR,
} from '@app/external/common/store/basic-params/basic-params-selectors';
import { getIsMemberAreaInstalled } from '@app/external/common/store/communities-context/communities-context-selectors';
import { getIsPostInPreview } from '@app/external/common/store/is-post-in-preview/is-post-in-preview-selectors';
import {
  getPostAverageRating,
  getPostTotalRatings,
} from '@app/external/common/store/post-ratings/post-ratings-selector';
import { type LayoutConfigItemComponentProps } from '@app/external/feed-page/components/post-list/layout-config-component-props';
import PostUpdatedDate from './post-updated-date';
import { Settings } from './settings-context';
import { shouldShowPostUpdateDate } from './utils';
import styles from './post.scss';

type PropsInPostPage = {
  isInPostPage?: boolean;
  onRatingsDisplayClick?: () => void;
};

type PostProps = Pick<LayoutConfigItemComponentProps, 'post'> & PropsInPostPage;

export const Post: React.FC<PostProps> = (props) => {
  const { isMobile } = useEnvironment();
  const [BelowContent1SlotsPlaceholder, isBelowContent1SlotEmpty] =
    useSlotPlaceholder(BELOW_CONTENT_1);
  const [BelowContent2SlotsPlaceholder, isBelowContent2SlotEmpty] =
    useSlotPlaceholder(BELOW_CONTENT_2);
  const { post, isInPostPage } = props;
  const { postPageMoreButtonClicked } = useActions();
  const {
    commentsLink,
    isCategoryLabelsEnabled,
    isDesktop,
    isPostRatingLoading,
    layoutName,
    postAverageRating,
    postLink,
    postTotalRatings,
    renodeRatings,
    showMoreButton,
    shouldApplyPostDesignInFeed,
  } = useSlice(props);

  const { titleFontClassName, contentFontClassName } = useFontClassName();

  const useSettings = isInPostPage
    ? usePostPageSettings
    : useFeedMetadataSettings;

  const settings = useSettings({ post });

  const {
    isMetadataHeaderVisible,
    showPostRating,
    showPostUpdatedDate,
    showPostTitle,
    showTags,
    showMoreOptionsMenu,
  } = settings;

  const { borderWidth } = usePostBorderWidth();
  const { applyFeedDesign, getPostClassName } = useIsFeedDesignEnabled();
  const containerClassName = classNames(
    styles.container,
    contentFontClassName,
    'blog-card-background-color',
    'blog-card-border-color',
    'blog-text-color',
    getPostClassName('border-color', 'post-container', 'background-color'),
  );
  const moreButtonId = `more-button-${resolveId(post)}`;
  const titleContainerClassName = classNames(
    styles.title,
    !isMetadataHeaderVisible && styles.withoutHeaderMetadata,
    post.isPinned && styles.withIcons,
    !isInPostPage && styles.notPostPage,
    showMoreButton && showMoreOptionsMenu
      ? styles.withShowMoreButton
      : styles.withoutShowMoreButton,
  );
  const ratingsVisible = isInPostPage
    ? showPostRating
    : showPostRating && !!postTotalRatings;

  const titleClassName = classNames(
    applyFeedDesign
      ? getPostClassName('title-font', 'title-color')
      : [titleFontClassName],
    'blog-post-title-color',
  );

  const metadataClassName = classNames(
    applyFeedDesign &&
      getPostClassName('description-font', 'description-color'),
  );
  const postContentClassName = classNames(
    styles.content,
    !ratingsVisible &&
      showPostUpdatedDate &&
      shouldShowPostUpdateDate(post) &&
      styles.postUpdateWithoutRating,
    applyFeedDesign &&
      getPostClassName('description-style-font', 'description-color'),
  );

  const headerRightClass =
    isDesktop && (isInPostPage || shouldApplyPostDesignInFeed)
      ? classNames(styles.headerRight, styles.postPageDesktopHeader)
      : styles.headerRight;

  const ratingsClassName = classNames(
    styles.postRating,
    layoutName === FEED_LAYOUT_TYPES[Layout.FullPost] && styles.postRatingFull,
  );

  return (
    <Settings.Provider value={{ settings }}>
      <article
        className={containerClassName}
        style={{ borderWidth }}
        data-hook="post"
      >
        <div className={styles.contentSpacer}>
          <div className={styles.contentWrapper}>
            <div
              className={classNames(
                styles.mobileContainer,
                styles.postTitleContainer,
              )}
            >
              {showPostTitle && (
                <div className={titleContainerClassName} data-hook="post-title">
                  <PostTitle
                    fullRoute={post.link}
                    isInPostPage={isInPostPage}
                    className={titleClassName}
                    type="single"
                    title={post.title}
                    to={postLink}
                    linkClassName={classNames(
                      getPostClassName('link-hashtag-hover-color'),
                    )}
                  />
                </div>
              )}
              <div className={styles.header}>
                {isMetadataHeaderVisible && (
                  <PostPageMetadata
                    post={post}
                    className={metadataClassName}
                    linkClassName={classNames(
                      getPostClassName('link-hashtag-hover-color'),
                    )}
                  />
                )}
                <div className={headerRightClass}>
                  <PostHeaderIcons post={post} />
                  {showMoreOptionsMenu && (
                    <MoreButton
                      className={classNames(
                        styles.moreButton,
                        styles.hideInPrint,
                      )}
                      id={moreButtonId}
                    >
                      {async () => {
                        postPageMoreButtonClicked({
                          postId: resolveId(post),
                        });
                        const PostActions = await importPostActions();
                        return <PostActions post={post} />;
                      }}
                    </MoreButton>
                  )}
                </div>
              </div>
            </div>
            {showPostUpdatedDate && <PostUpdatedDate post={post} />}
            {ratingsVisible && (
              <button
                className={ratingsClassName}
                onClick={props.onRatingsDisplayClick}
              >
                <RatingsDisplay
                  rating={postAverageRating}
                  count={postTotalRatings}
                  isLoading={isInPostPage ? isPostRatingLoading : false}
                  useTransitions={renodeRatings || isPostRatingLoading}
                  layout={
                    isMobile
                      ? RatingsDisplayLayout.bracket_separated
                      : RatingsDisplayLayout.default
                  }
                />
              </button>
            )}
          </div>
        </div>
        <div className={postContentClassName} data-hook="post-description">
          <PostContent post={post} />
        </div>
        {!isBelowContent1SlotEmpty && (
          <div
            className={classNames(
              styles.mobileContainer,
              styles.belowContentSlot,
            )}
          >
            <BelowContent1SlotsPlaceholder />
          </div>
        )}
        {!isBelowContent2SlotEmpty && (
          <div
            className={classNames(
              styles.mobileContainer,
              styles.belowContentSlot,
            )}
          >
            <BelowContent2SlotsPlaceholder />
          </div>
        )}
        <div className={styles.contentSpacer}>
          <div className={styles.contentWrapper}>
            <div
              id="post-footer"
              className={classNames(styles.mobileContainer, styles.hideInPrint)}
            >
              {showTags && (
                <TagsWithTranslation
                  postId={post.id}
                  isPostPageTags={true}
                  tags={post.tags}
                  shouldSortTags={false}
                />
              )}
              {isCategoryLabelsEnabled && !isDesktop && (
                <div
                  className={styles.categoryList}
                  data-hook="post__categories"
                >
                  <CategoryLabelList post={post} />
                </div>
              )}

              <PostMainActions
                post={post}
                commentsLink={commentsLink}
                layoutName={layoutName}
              />
            </div>
          </div>
        </div>
      </article>
    </Settings.Provider>
  );
};

function useSlice({ post, isInPostPage }: PostProps) {
  const { canSee } = usePermissions();

  return useSelector((state) => {
    const section = getSection(state) || SECTION_HOMEPAGE;
    const postActions = getPostActions({
      post,
      canSee,
      enableShare: true,
      enableSubscribe: getIsMemberAreaInstalled(state),
    });
    const isPostInPreview = getIsPostInPreview(state);
    const showMoreButton = !isPostInPreview && Boolean(postActions.length);
    const postAverageRating = getPostAverageRating(state, resolveId(post));
    const postTotalRatings = getPostTotalRatings(state, resolveId(post));
    const isPostRatingLoading =
      typeof postAverageRating === 'undefined' &&
      typeof postTotalRatings === 'undefined';

    return {
      postLink: isInPostPage ? undefined : `/${post.slug}`,
      commentsLink: isInPostPage ? undefined : post.link,
      showMoreButton,
      shouldApplyPostDesignInFeed: getShouldApplyPostDesignInFeed(state),
      layoutName: getLayoutName(getLayoutType(state, section)!),
      isDesktop: getIsDesktop(state),
      isCategoryLabelsEnabled: getIsCategoryLabelsEnabled(state),
      isMoreButtonEnabled: getIsMoreButtonEnabled(state, showMoreButton),
      postAverageRating,
      postTotalRatings,
      isPostRatingLoading,
      /**
        We don't have ratings information in SSR and sometimes initial CSR render will have ratings fetched,
        which will result in hydration error. In that case we render ratings in different element for CSR
      */
      renodeRatings: !isSSR(state) && !isPostRatingLoading,
    };
  });
}

export default Post;
