import { useExperiments } from '@wix/yoshi-flow-editor';
import { useStyles } from '@wix/tpa-settings/react';
import {
  AUTHOR_INFO_TYPE_NAME,
  hasTPACommentsSettings,
  getTPASettingsIsCommentsEnabled,
} from '@wix/communities-blog-client-common';
import stylesParams from '@app/components/Post/stylesParams';
import { OOI_EXPERIMENTS } from '@app/experiments';
import { useSelector } from '../components/runtime-context';
import {
  getIsViewCountEnabled,
  getIsCommentCountEnabled,
  getIsLikeCountEnabled,
  getIsAuthorNameEnabled,
  getIsPostUpdatedDateEnabled,
  getIsCommentsEnabled,
  getIsRecentPostsEnabled,
  getIsCategoryLabelsEnabled,
  getIsPostTitleEnabled,
  getIsPostPublishDateEnabled,
  getIsReadingTimeEnabled,
  getAuthorInfoType,
  getIsAuthorPictureEnabled,
  getIsRelatedPostsEnabled,
  getRelatedPostsLabel,
  getIsCategoryLabelEnabled,
  getIsPostRatingEnabled,
  getIsTagsEnabled,
  getIsMoreOptionsMenuEnabled,
} from '../selectors/app-settings-selectors';
import { getIsPostInPreview } from '../selectors/is-post-in-preview';
import { getMainCategory } from '../store/categories/categories-selectors';
import { type NormalizedPost } from '../types';

export type UsePostPageSettings = ReturnType<typeof usePostPageSettings>;

type UsePostPageSettingsParams = {
  post: NormalizedPost;
};

export const usePostPageSettings = (params: UsePostPageSettingsParams) => {
  const styles = useStyles();
  const { experiments } = useExperiments();
  return useSelector((state) => {
    const isPostInPreview = getIsPostInPreview(state);
    const isCommentsEnabled = isPostInPreview
      ? false
      : hasTPACommentsSettings(state)
      ? getTPASettingsIsCommentsEnabled(state)
      : getIsCommentsEnabled(state);

    if (experiments.enabled(OOI_EXPERIMENTS.USE_NEW_POST_PAGE_SETTINGS_URL)) {
      return {
        showComments: isCommentsEnabled,
        showRecentPosts: true,
        showViewCount: styles.get(stylesParams.showViewCount),
        showCommentCount: styles.get(stylesParams.showCommentCount),
        showLikeCount: styles.get(stylesParams.showLikeCount),
        showAuthorName: styles.get(stylesParams.showAuthorName),
        showAuthorPicture: styles.get(stylesParams.showAuthorPicture),
        showPostUpdatedDate: styles.get(stylesParams.showPostUpdatedDate),
        showCategoryLabels: styles.get(stylesParams.showCategoryLabel),
        showPostTitle: styles.get(stylesParams.showPostTitle),
        showPublishDate: styles.get(stylesParams.showPostPublishDate),
        showReadingTime: styles.get(stylesParams.showReadingTime),
        showTags: styles.get(stylesParams.showTags),
        showMoreOptionsMenu: styles.get(stylesParams.showMoreOptionsMenu),
        isMetadataFooterVisible: true,
        isMetadataHeaderVisible: true,
        showRelatedPosts: true,
        relatedPostsLabelKey: undefined,
        showPostRating: styles.get(stylesParams.showPostRating),
      };
    }

    const isRecentPostsEnabled =
      !isPostInPreview && getIsRecentPostsEnabled(state);
    const isRelatedPostsEnabled =
      !isPostInPreview && getIsRelatedPostsEnabled(state);
    const relatedPostsLabelKey = !isPostInPreview
      ? getRelatedPostsLabel(state)
      : undefined;
    const showViewCount = !isPostInPreview && getIsViewCountEnabled(state);
    const showCommentCount =
      isCommentsEnabled && getIsCommentCountEnabled(state);
    const showLikeCount = !isPostInPreview && getIsLikeCountEnabled(state);
    const showAuthorName = getIsAuthorNameEnabled(state);
    const authorInfoType = getAuthorInfoType(state);
    const showPostUpdatedDate = getIsPostUpdatedDateEnabled(state);
    const showCategoryLabels = getIsCategoryLabelsEnabled(state);
    const showPostTitle = getIsPostTitleEnabled(state);
    const showPublishDate = getIsPostPublishDateEnabled(state);
    const showReadingTime = getIsReadingTimeEnabled(state);
    const oldShowAuthorPictureValue = authorInfoType !== AUTHOR_INFO_TYPE_NAME;
    const showAuthorPicture = getIsAuthorPictureEnabled(
      state,
      oldShowAuthorPictureValue,
    );
    const showCategoryLabel = getMainCategory(state, params.post)
      ? getIsCategoryLabelEnabled(state)
      : false;
    const showPostRating = getIsPostRatingEnabled(state);
    const showTags = getIsTagsEnabled(state);
    const showMoreOptionsMenu = getIsMoreOptionsMenuEnabled(state);

    return {
      isMetadataFooterVisible:
        !isPostInPreview &&
        (showViewCount || showCommentCount || showLikeCount),
      isMetadataHeaderVisible:
        showAuthorName || showPublishDate || showReadingTime,
      relatedPostsLabelKey,
      showAuthorName,
      showAuthorPicture,
      showCategoryLabel,
      showCategoryLabels,
      showCommentCount,
      showComments: isCommentsEnabled,
      showLikeCount,
      showPostRating,
      showPostTitle,
      showPostUpdatedDate,
      showPublishDate,
      showReadingTime,
      showRecentPosts: isRecentPostsEnabled,
      showRelatedPosts: isRelatedPostsEnabled,
      showViewCount,
      showTags,
      showMoreOptionsMenu,
    };
  });
};
