import { useExperiments, useEnvironment } from '@wix/yoshi-flow-editor';
import { useStyles } from '@wix/tpa-settings/react';
import { getIsPostPageBundle } from '@wix/communities-blog-client-common';
import stylesParams from '@app/components/Post/stylesParams';
import { OOI_EXPERIMENTS } from '@app/experiments';
import { useSelector } from '../components/runtime-context';
import {
  getIsBlogMenuEnabled,
  getIsBlogMenuCategoryLabelsEnabled,
  getIsBlogMenuSearchEnabled,
  getIsBlogMenuLoginButtonEnabled,
} from '../selectors/app-settings-selectors';

type BlogMenuSettings = {
  showBlogMenu: boolean;
  showCategoryLabels: boolean;
  showSearch: boolean;
  showLoginButton: boolean;
};

const useBlogMenuSettings = (): BlogMenuSettings => {
  const { experiments } = useExperiments();
  const styles = useStyles();
  const { isEditorX: isStudio } = useEnvironment();

  const isPostPage = useSelector(getIsPostPageBundle);

  return useSelector((state): BlogMenuSettings => {
    if (
      isPostPage &&
      experiments.enabled(OOI_EXPERIMENTS.USE_NEW_POST_PAGE_SETTINGS_URL)
    ) {
      return {
        showBlogMenu: isStudio
          ? styles.get(stylesParams.showMenuCategoryLabels)
          : styles.get(stylesParams.showMenu),
        showCategoryLabels: styles.get(stylesParams.showMenuCategoryLabels),
        showSearch: styles.get(stylesParams.showMenuSearch),
        showLoginButton: styles.get(stylesParams.showMenuLoginButton),
      };
    }

    return {
      showBlogMenu: getIsBlogMenuEnabled(state),
      showCategoryLabels: getIsBlogMenuCategoryLabelsEnabled(state),
      showSearch: getIsBlogMenuSearchEnabled(state),
      showLoginButton: getIsBlogMenuLoginButtonEnabled(state),
    };
  });
};

export default useBlogMenuSettings;
