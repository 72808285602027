import React from 'react';
import classNames from 'classnames';
import { chain, map, take } from 'lodash';
import PropTypes from 'prop-types';

import {
  getCategoryPath,
  getMixedIdsMap,
} from '@wix/communities-blog-client-common';

import { getCategoriesMap } from '../../store/categories/categories-selectors';
import Link from '../link/internal-link';
import List from '../list';
import { connect } from '../runtime-context';
import styles from './category-label-list.scss';

const DISPLAY_COUNT = 3;

const renderCategoryItem = (category, index, LinkComponent, categoryPath) =>
  category && (
    <LinkComponent
      className={classNames(
        'blog-text-color',
        'post-categories-list__link',
        styles.categoryPostLink,
      )}
      to={`/${categoryPath}/${category.slug}`}
      key={index}
      data-hook="category-label-list__item"
    >
      {category.menuLabel || category.label}
    </LinkComponent>
  );

export const CategoryLabelList = ({
  post,
  categories,
  isCategoryLinksEnabled = true,
  categoryPath,
}) => {
  let categoriesToDisplay;

  if (post.categories) {
    categoriesToDisplay = take(post.categories, DISPLAY_COUNT);
  } else {
    const categoryIdMap = getMixedIdsMap(categories);
    categoriesToDisplay = chain(categoryIdMap)
      .pick(post.categoryIds)
      .values()
      .take(DISPLAY_COUNT)
      .value();
  }

  const count = categoriesToDisplay.length;

  if (!count) {
    return null;
  }

  const LinkComponent = isCategoryLinksEnabled ? Link : 'span';

  return (
    <List
      containerClassName={styles.container}
      itemClassName={styles.item}
      separatorClassName={styles.separator}
      separator={<span>&bull;</span>}
    >
      {map(categoriesToDisplay, (category, index) =>
        renderCategoryItem(category, index, LinkComponent, categoryPath),
      )}
    </List>
  );
};

CategoryLabelList.propTypes = {
  categories: PropTypes.object.isRequired,
  post: PropTypes.object.isRequired,
  isCategoryLinksEnabled: PropTypes.bool,
  categoryPath: PropTypes.string,
};

const mapRuntimeToProps = (state) => ({
  categories: getCategoriesMap(state),
  categoryPath: getCategoryPath(state),
});

export default connect(mapRuntimeToProps)(CategoryLabelList);
