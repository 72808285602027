import React from 'react';
import App from '@app/external/common/components/app';
import CategoriesLink from '@app/external/common/components/categories-link/change-category';
import CategoryDesktopHeader from '@app/external/common/components/category-desktop-header';
import CategoryMobileHeader from '@app/external/common/components/category-mobile-header';
import PopoverRoot from '@app/external/common/components/popovers/popover-root';
import type { MatchedRoute } from '@app/external/common/router/hooks';
import MessageRoot from '../../components/messages/message-root';
import ModalRoot from '../../components/modals/modal-root';

export const WrapComponent =
  (Component: React.FC<{ params: any }>) => (props: MatchedRoute) =>
    (
      <App
        main={<Component params={props.params} />}
        modalRoot={<ModalRoot />}
        messageRoot={<MessageRoot />}
        popoverRoot={<PopoverRoot />}
        mobileHeader={<CategoryMobileHeader />}
        mobileMenu={<CategoriesLink />}
        desktopHeader={
          <CategoryDesktopHeader
            postSlug={props.params?.postSlug ?? undefined}
          />
        }
      />
    );
