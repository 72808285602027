import React from 'react';
import classNames from 'classnames';
import {
  getIsAppBundle,
  getIsPostPageBundle,
} from '@wix/communities-blog-client-common';
import useBlogMenuSettings from '../../hooks/use-blog-menu-settings';
import { getPostBySlug } from '../../selectors/post-selectors';
import DesktopHeader from '../desktop-header';
import HeaderNavigation from '../header-navigation';
import HeaderWidget from '../header-widget';
import { useSelector } from '../runtime-context';
import SearchInput from '../search-input';
import styles from './category-desktop-header.scss';

type Props = {
  postSlug?: string;
};

const CategoryDesktopHeader: React.FC<Props> = ({ postSlug }) => {
  const { showSearch, showCategoryLabels } = useBlogMenuSettings();
  const isAppBundle = useSelector(getIsAppBundle);
  const isPostPageBundle = useSelector(getIsPostPageBundle);
  const post = useSelector((state) =>
    postSlug ? getPostBySlug(state, postSlug) : null,
  );

  return (
    <DesktopHeader
      className={classNames(
        styles.container,
        isAppBundle && styles.noMargin,
        isPostPageBundle && styles.inPostPage,
      )}
      left={showCategoryLabels ? <HeaderNavigation /> : <div />}
      right={
        <HeaderWidget
          searchInput={
            showSearch ? (
              <SearchInput
                post={post}
                inputClassName="blog-desktop-header-search-text-color blog-desktop-header-search-font"
                borderClassName="blog-desktop-header-search-border-background-color"
                iconClassName="blog-desktop-header-search-icon-fill"
              />
            ) : null
          }
        />
      }
    />
  );
};

export default CategoryDesktopHeader;
