import React from 'react';
import { getPostCover } from '@wix/communities-blog-client-common';
import { type NormalizedPost } from '@app/external/common/types';
import RecentPostsListItemWithCover from './recent-posts-list-item-with-cover';
import RecentPostsListItemWithoutCover from './recent-posts-list-item-without-cover';
import styles from './recent-posts-list-item.scss';

type Props = {
  post: NormalizedPost;
  isMetadataFooterVisible: boolean;
};

export const RecentPostsListItem: React.FC<Props> = (props) => {
  const { post, isMetadataFooterVisible } = props;
  const postCover = getPostCover(post);
  const Component = postCover?.shouldRender
    ? RecentPostsListItemWithCover
    : RecentPostsListItemWithoutCover;

  return (
    <article
      className={styles.article}
      data-hook="recent-post-list-item"
      style={{
        paddingTop: `calc(56.25% + ${
          isMetadataFooterVisible ? '148px' : '103px'
        })`,
      }}
    >
      <div className={styles.container}>
        <Component
          isMetadataFooterVisible={isMetadataFooterVisible}
          post={post}
        />
      </div>
    </article>
  );
};

export default RecentPostsListItem;
