import { createStylesParams, StyleParamType } from '@wix/tpa-settings';
import { type CustomCssVarsFn } from '@wix/yoshi-flow-editor';
import {
  ContentSizing,
  postContentStyleParams,
} from '@wix/communities-blog-client-common';
import { resolveDefaultStyleParamValue } from '@app/common/style-params';
import * as LEGACYSTYLEPARAMS from './legacyStylesParams';

export const customCssVars: CustomCssVarsFn = (tpaParams) => {
  return {
    'postContent-contentWidthInPxOrPercent': contentWidth(),
  };

  function contentWidth(): `${number}px` | `${number}%` {
    const contentSizing: ContentSizing =
      tpaParams.styleParams.numbers[
        postContentStyleParams['postContent-contentSizing'].key
      ];
    const contentSizingInPx =
      tpaParams.styleParams.numbers[
        postContentStyleParams['postContent-contentWidthInPx'].key
      ];
    const contentSizingInPercent =
      tpaParams.styleParams.numbers[
        postContentStyleParams['postContent-contentWidthInPercent'].key
      ];

    return contentSizing === ContentSizing.Fixed
      ? `${contentSizingInPx}px`
      : `${contentSizingInPercent}%`;
  }
};

type IStylesParams = {
  showAuthorName: StyleParamType.Boolean;
  showAuthorPicture: StyleParamType.Boolean;
  showPostPublishDate: StyleParamType.Boolean;
  showReadingTime: StyleParamType.Boolean;
  showPostTitle: StyleParamType.Boolean;
  showPostUpdatedDate: StyleParamType.Boolean;
  showCategoryLabel: StyleParamType.Boolean;
  /** Classic only */
  showMenu: StyleParamType.Boolean;
  /** Should behave on Studio same as showMenu (sets menu visibility) */
  showMenuCategoryLabels: StyleParamType.Boolean;
  /** Classic only */
  showMenuLoginButton: StyleParamType.Boolean;
  /** @deprecated Legacy, Classic only */
  showMenuSearch: StyleParamType.Boolean;
  showTags: StyleParamType.Boolean;
  showMoreOptionsMenu: StyleParamType.Boolean;
  showPostRating: StyleParamType.Boolean;
  showCommentCount: StyleParamType.Boolean;
  showViewCount: StyleParamType.Boolean;
  showLikeCount: StyleParamType.Boolean;
};

export default createStylesParams<IStylesParams>({
  showAuthorName: {
    type: StyleParamType.Boolean,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACYSTYLEPARAMS.showAuthorName,
        mobileParamName: LEGACYSTYLEPARAMS.showAuthorNameMobile,
        dependencyParam: LEGACYSTYLEPARAMS.isMobileDisplaySettingsEnabled,
      }),
  },

  showAuthorPicture: {
    type: StyleParamType.Boolean,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACYSTYLEPARAMS.showAuthorPicture,
        mobileParamName: LEGACYSTYLEPARAMS.showAuthorPictureMobile,
        dependencyParam: LEGACYSTYLEPARAMS.isMobileDisplaySettingsEnabled,
      }),
  },

  showPostPublishDate: {
    type: StyleParamType.Boolean,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACYSTYLEPARAMS.showPostPublishDate,
        mobileParamName: LEGACYSTYLEPARAMS.showPostPublishDateMobile,
        dependencyParam: LEGACYSTYLEPARAMS.isMobileDisplaySettingsEnabled,
      }),
  },

  showReadingTime: {
    type: StyleParamType.Boolean,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACYSTYLEPARAMS.showReadingTime,
        mobileParamName: LEGACYSTYLEPARAMS.showReadingTimeMobile,
        dependencyParam: LEGACYSTYLEPARAMS.isMobileDisplaySettingsEnabled,
      }),
  },

  showPostTitle: {
    type: StyleParamType.Boolean,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACYSTYLEPARAMS.showPostTitle,
        mobileParamName: LEGACYSTYLEPARAMS.showPostTitleMobile,
        dependencyParam: LEGACYSTYLEPARAMS.isMobileDisplaySettingsEnabled,
      }),
  },

  showPostUpdatedDate: {
    type: StyleParamType.Boolean,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACYSTYLEPARAMS.showPostUpdatedDate,
        mobileParamName: LEGACYSTYLEPARAMS.showPostUpdatedDateMobile,
        dependencyParam: LEGACYSTYLEPARAMS.isMobileDisplaySettingsEnabled,
      }),
  },

  showCategoryLabel: {
    type: StyleParamType.Boolean,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACYSTYLEPARAMS.showCategoryLabel,
        mobileParamName: LEGACYSTYLEPARAMS.showCategoryLabelMobile,
        dependencyParam: LEGACYSTYLEPARAMS.isMobileDisplaySettingsEnabled,
      }),
  },

  showMenu: {
    type: StyleParamType.Boolean,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACYSTYLEPARAMS.showMenu,
        mobileParamName: LEGACYSTYLEPARAMS.showMenuMobile,
        dependencyParam: LEGACYSTYLEPARAMS.isMobileDisplaySettingsEnabled,
      }),
  },
  showMenuLoginButton: {
    type: StyleParamType.Boolean,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACYSTYLEPARAMS.showMenuLoginButton,
        mobileParamName: LEGACYSTYLEPARAMS.showMenuLoginButtonMobile,
        dependencyParam: LEGACYSTYLEPARAMS.isMobileDisplaySettingsEnabled,
      }),
  },
  showMenuCategoryLabels: {
    type: StyleParamType.Boolean,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACYSTYLEPARAMS.showMenuCategoryLabels,
        mobileParamName: LEGACYSTYLEPARAMS.showMenuCategoryLabelsMobile,
        dependencyParam: LEGACYSTYLEPARAMS.isMobileDisplaySettingsEnabled,
      }),
  },
  showMenuSearch: {
    type: StyleParamType.Boolean,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACYSTYLEPARAMS.showMenuSearch,
        mobileParamName: LEGACYSTYLEPARAMS.showMenuSearchMobile,
        dependencyParam: LEGACYSTYLEPARAMS.isMobileDisplaySettingsEnabled,
      }),
  },
  showTags: {
    type: StyleParamType.Boolean,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACYSTYLEPARAMS.showTags,
        mobileParamName: LEGACYSTYLEPARAMS.showTagsMobile,
        dependencyParam: LEGACYSTYLEPARAMS.isMobileDisplaySettingsEnabled,
      }),
  },
  showMoreOptionsMenu: {
    type: StyleParamType.Boolean,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACYSTYLEPARAMS.showMoreOptionsMenu,
        mobileParamName: LEGACYSTYLEPARAMS.showMoreOptionsMenuMobile,
        dependencyParam: LEGACYSTYLEPARAMS.isMobileDisplaySettingsEnabled,
      }),
  },

  showPostRating: {
    type: StyleParamType.Boolean,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACYSTYLEPARAMS.showPostRating,
        mobileParamName: LEGACYSTYLEPARAMS.showPostRatingMobile,
        dependencyParam: LEGACYSTYLEPARAMS.isMobileDisplaySettingsEnabled,
      }),
  },

  showCommentCount: {
    type: StyleParamType.Boolean,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACYSTYLEPARAMS.showCommentCount,
        mobileParamName: LEGACYSTYLEPARAMS.showCommentCountMobile,
        dependencyParam: LEGACYSTYLEPARAMS.isMobileDisplaySettingsEnabled,
      }),
  },

  showViewCount: {
    type: StyleParamType.Boolean,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACYSTYLEPARAMS.showViewCount,
        mobileParamName: LEGACYSTYLEPARAMS.showViewCountMobile,
        dependencyParam: LEGACYSTYLEPARAMS.isMobileDisplaySettingsEnabled,
      }),
  },

  showLikeCount: {
    type: StyleParamType.Boolean,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACYSTYLEPARAMS.showLikeCount,
        mobileParamName: LEGACYSTYLEPARAMS.showLikeCountMobile,
        dependencyParam: LEGACYSTYLEPARAMS.isMobileDisplaySettingsEnabled,
      }),
  },
});
